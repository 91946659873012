.e-classifed-tag-button {
  border-radius: 1.125rem !important;

  &.outline-not-selected{
    border-color: #BFBFBF;
    border-width: 1px;
    border-style: solid;
    &:hover{
      color: #fff;
    }
  }
  &.not-selected{
    border-color: #BFBFBF;
    border-width: 1px;
    border-style: solid;
    &:hover{
      color: #fff;
    }
  }
}