.modal-trigger {
    display: flex;
    padding: 0.875rem 1rem;
    align-items: center;
    gap: 0.5rem;
    height: calc(1.5em + 0.75rem + 2px);
    font-size: 0.875rem;
    font-weight: 300;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #d0d5dd;
    background: #fff;
    line-height: 1.25rem;
    position: relative;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  
    &:focus {
      color: #495057;
      background-color: #fff;
      border-color: #6c8cc3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(51, 78, 124, 0.24);
    }
  }
  
  .modal-trigger-sm {
    display: flex;
    padding: 0rem 1rem;
    align-items: center;
    gap: 0.5rem;
    height: 2.35rem;
    font-size: 0.875rem;
    font-weight: 300;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #d0d5dd;
    background: #fff;
    line-height: 1.25rem;
    position: relative;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #000000;
  
    &:focus {
      color: #495057;
      background-color: #fff;
      border-color: #6c8cc3;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(51, 78, 124, 0.24);
    }
  }