.custom-carousel-container{
  .square-container {
    width: 100%; /* Set the width of the container as a percentage of its parent or any specific value you want */
    padding-top: 100%; /* Set the padding-top to be the same as the width to create a square */
    position: relative; /* For positioning child elements inside the square */
  }
  
  .square-container > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}