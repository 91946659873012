@import "../../../stylesheets/base.scss";

$job-box-shadow: 0px 4px 6px rgba(147, 147, 147, 0.25);
$job-box-radius: 0.5rem;

.e-classified-advertiser__preview-job {
  box-shadow: none !important;
  background: none;
  border: none;
  align-items: center;
  justify-content: center;
  height: 100%;

  .modal-content {
    width: 100%;
    height: 90%;
  }

.e-classified-advertiser__preview-job-body {
    padding: 0;
    height: min-content;
    display: flex;
    flex-wrap: wrap;

    .e-classified-advertiser__preview-job-body-header {
      width: 100%;
      height: 8.5rem;
      background: linear-gradient(
        180deg,
        #f0f4ff 37.19%,
        rgba(240, 244, 255, 0) 106.77%
      );
      padding: 0 3.5rem 0 3.5rem;

      .header-button-col {
        justify-content: right;
        display: flex;
        gap: 1rem;
        left: 3rem;
      }

      .header-button {
        font-size: 1.5rem;
        cursor: pointer;
        color: $primary;
      }
    }
  }

  .e-classified-advertiser__image-slide {
    width: 100%;
    
    .carousel-indicators {
      bottom: -2rem;

      button {
        width: 10px;
        height: 10px !important;
        border-radius: 10px;
        border: none;
        background-color: var(--secondary);
      }

      .active {
        background-color: var(--primary);
        height: 10px;
        width: 20px !important;
        border-radius: 10px;
        border: none;
      }
    }

    .carousel-control-prev {
      width: 2rem;
      left: -2rem;

      svg {
        font-size: 2.5rem;
        cursor: pointer;
        color: var(--primary);
      }
    }

    .carousel-control-next {
      width: 2rem;
      right: -2rem;

      svg {
        font-size: 2.5rem;
        cursor: pointer;
        color: var(--primary);
      }
    }
  }
  
  // Job Details
  .e-classified-cont {
    padding: 0;

    .e-classified-job-details-cont {
      margin-bottom: 3.75rem;

      .e-classified-job-details-cont__logo-cont {
        background: white;
        min-width: 7.5rem;
        min-height: 7.5rem;
        max-width: 7.5rem;
        max-height: 7.5rem;
        display: flex;
        border-radius: 50%;
        box-shadow: $job-box-shadow;
        align-items: center;
        justify-content: center;
        
        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .e-classified-job-details-cont__logo-title-cont {
          width: calc(100% - 16rem);
          margin-right: 1.1875;

          h5 {
            font-size: 1.5rem;
            margin-bottom: 0.5rem;
          }

          span {
            font-size: 0.8;
          }
        }

        svg {
          cursor: pointer;
          color: #2254f5;
          width: 2rem;
          height: 2rem;
        }
      }

      .e-classified-job-details-cont__card {
        box-shadow: $job-box-shadow;
        width: 9.375rem;
        padding: 0.75rem 0rem;
        display: grid;
        border-radius: $job-box-radius;
        place-items: center;
        margin-top: 2rem;
        margin-right: 1rem;

        p {
          text-align: center;
          
          &:first-child {
            font-size: 0.875rem;
            margin-bottom: 0.5rem !important;
          }

          &:last-child {
            font-size: 1.125rem;
            color: #2254f5;
            font-weight: 600;
          }
        }
      }

      .e-classified-job-details-cont__position-cont,
      .e-classified-job-details-cont__request-cont {
        margin-top: 2.5rem;

        h3 {
          margin-bottom: 0.5rem;
          font-size: 1.125rem;
          font-weight: 600;
        }

        p {
          font-size: 1rem;
        }

        li {
          font-size: 0.8rem;
          margin-bottom: 0.5rem;
        }

        ul {
          margin-left: 2rem;
        }
      }

      .e-classified-job-details-cont__summary-cont {
        box-shadow: $job-box-shadow;
        border-radius: $job-box-radius;
        padding: 1.75rem;
        margin-bottom: 2.5rem;
        background: white;
        

        .e-classified-job-details-cont__summary-cont-salary-title-cont {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;

          svg {
            color: #1e4d5a;
            width: 1.125rem;
            height: 1.125rem;
            margin-right: 0.5rem;
          }

          span {
            font-size: 1rem;
            color: #434343;
          }
        }

        .e-classified-job-details-cont__summary-cont-salary-text {
          font-size: 1.5rem;
          font-weight: 600;
          margin-bottom: 1rem;
        }

        .e-classified-job-details-cont__summary-cont-badge {
          padding: 0.25rem 0.5rem;
          color: white;
          margin-right: 0.5rem;
          border-radius: 0.25rem;

          span {
            font-size: 0.875;
          }
        }

        .e-classified-job-details-cont__summary-cont-action-cont {
          display: flex;
          gap: 1.2rem;
          row-gap: 1.2rem; /* Fallback for older browsers */
          column-gap: 1.2rem; /* Fallback for older browsers */
          flex-wrap: wrap;
          margin-top: 1rem;

          button {
            display: flex;
            align-items: center;

            svg {
              width: 1rem;
              margin-left: 0.5rem;
            }
          }
        }
      }

      .e-classified-job-details-cont__company-details-cont {
        padding: 1.5rem 1.875rem;
        box-shadow: $job-box-shadow;
        border-radius: $job-box-radius;
        margin-bottom: 2.5rem;

        .e-classified-job-details-cont__company-details-title {
          font-size: 1.125rem;
          font-weight: 600;
          margin-bottom: 1.5rem;
        }

        .e-classified-job-details-cont__company-details-cont-title-cont {
          display: flex;
          align-items: center;

          img {
            width: 3.125rem;
				    height: 3.125rem;
            object-fit: cover;
				    border-radius: 50%;
            box-shadow: $job-box-shadow;
            
          }
          .e-classified-job-details-cont__company-details-cont-title-cont-text-cont {
            width: calc(100% - 4.125rem);
            p {
              font-size: 1rem;
              margin-left: 1rem;
              white-space: nowrap;
            }
          }
        }

        .e-classified-job-details-cont__company-details-cont-data {
          margin-top: 1rem;

          svg {
            width: 1.125rem;
            height: 1.125rem;
            cursor: pointer;
            margin-right: 0.5rem;
          }

          span {
            font-size: 1rem;
            margin-right: 0.5rem;
          }

          .detail-title, .others {
            text-indent: -1.3em;
            padding-left: 1.3em;
          }

          .social-media, .company-address {
            white-space: wrap;
          }
  
          .social-media {
            word-break: break-all;
          }
  
          .company-address {
            word-break: break-word;
          }

          :last-child {
            color: $primary;
          }
        }

        .not-logged-in {
          span:last-child {
            filter: blur(5px);
          }
        }

      }

      .e-classified-job-details-cont__company-benefits {
        box-shadow: $job-box-shadow;
        border-radius: $job-box-radius;
        padding: 1.5rem 1.875rem;
        min-height: 15rem;
        max-height: 30rem;
        overflow: auto;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;

        .e-classified-job-details-cont__company-benefits-title {
          font-size: 1.125rem;
          font-weight: 600;
          margin-bottom: 1.5rem;
        }

        .e-classified-job-details-cont__company-benefits-card {
          padding: 2px;
          background-color: #f0f4ff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 0.375rem;
          // margin-top: 1rem;
          min-height: 6rem;
          text-align: center;
          word-break: break-word;

          svg {
            color: $primary;
            width: 1.5rem;
            height: 1.5rem;
          }

          img {
            color: $primary;
            width: 1.5rem;
            height: 1.5rem;
          }

          span {
            font-size: 0.8rem;
          }
        }

        .row .col-3 {
          padding: .2rem;
        }
      }

      .e-classified-job-details-cont__company-env-cont {
        margin-top: 3.75rem;
        padding: 20px;

        h5 {
          font-weight: 600;
          font-size: 1.5rem;
          margin-bottom: 1.5rem;
        }

        .bg-secondary {
          margin: 0 0.5rem 1.5rem 0.5rem;
          width: 17.5rem;
          height: 12.5rem;
        }
      }
    }
  }

  ::-webkit-scrollbar {
    width: 0.35rem;
    height: 0.35rem;
  }

  ::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 10px;
    border: none;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 10px;
    border: none;
  }
}
