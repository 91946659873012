// eClassified Jobs Container
// Style

@import "../../../../../stylesheets/base.scss";

.e-classified-job__mobile-filter-button {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 2rem;
	font-size: 1.2rem;
	color: $primary;
}

.e-classified-job__recommended-job-container {
	width: "100%";
	border-radius: 0.5rem;
	padding: 1rem;
	margin-bottom: 2rem;
	box-shadow: 0px 4px 6px rgba(175, 175, 175, 0.25);
}
.e-classified-job__recommended-job-logo-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
.e-classified-job__recommended-job-slide-show-button {
	padding: 0rem;

	svg {
		color: #f0f0f0 !important;
	}
}
.e-classified-job__recommended-job-logo {
	width: 10rem;
	height: 10rem;
	border-radius: 0.375rem;
	display: flex;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	img {
		width: 100%;
		object-fit: cover;
		align-self: center;
	}
	@media screen and (max-width: 767px) {
		width: 15rem;
		height: 15rem;
	}
}
.e-classified-job__recommended-job-title-container {
	text-decoration: none;
}
.e-classified-job__recommended-job-title {
	font-weight: 500;
	font-size: 0.875rem;
	text-align: center;
}
.e-classified-job__filter-container {
	display: flex;
	flex-direction: column;
	border-radius: 0.5rem;
	box-shadow: 0px 4px 6px rgba(175, 175, 175, 0.25);
	width: 100%;
}
.e-classified-job__filter-section-container {
	margin-top: 0.625rem;
	padding-left: 1rem;
	padding-right: 1rem;
	&.title {
		padding-bottom: 0.625rem;
		border-bottom-width: 1px;
		border-bottom-style: solid;
		border-color: #d9d9d9;
	}
	&.expand {
		margin-bottom: 1.5rem;
	}
	@media screen and (max-width: 767px) {
		font-size: 1.1rem
	}
}
.e-classified-job__filter-title-container {
	flex: 1;
}
.e-classified-job__filter-title {
	font-weight: 600;
	font-size: 1rem;
	text-align: left;
	@media screen and (max-width: 767px) {
		font-size: 1.2rem
	}
}
.e-classified-job__filter-accordion {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0rem;
	width: 100%;
}
.e-classified-job__filter-accordion-indicator-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}
.e-classified-job__filter-accordion-content-container {
	margin-top: 1.25rem;
	&.slider {
		margin-top: 1.75rem;
	}
}
.e-classified-job__filter-accordion-row-container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-top: 0.625rem;
	padding-bottom: 0.625rem;
	padding-left: 0.625rem;
	padding-right: 0.625rem;

	input {
		margin-right: 0px;
		margin-left: -0.8rem !important;
	}
}
.e-classified-job__filter-checkbox-label {
	font-weight: 400;
	font-size: 1rem;
	text-align: left;
	margin-left: 0.5rem;
	flex: 1;
	@media (max-width: 767px) {
		font-size: 1.2rem
	}
}
.e-classified-job__slider {
	display: flex;
	width: 100%;
	margin-bottom: 1rem;
}
.e-classified-job__slider-knob-container {
	width: 0.75rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.e-classified-job__slider-knob {
	width: 0.75rem;
	height: 0.75rem;
	border-radius: 50%;
	position: relative;
	top: -0.25rem;
	background-color: $primary;
}
.e-classified-job__slider-track {
	height: 0.25rem;
	border-radius: 0.625rem;
}
.e-classified-job__slider-track-0 {
	background: #d9d9d9;
}
.e-classified-job__slider-track-1 {
	background: $primary;
}
.e-classified-job__slider-track-2 {
	background: #d9d9d9;
}
.e-classified-job__filter-show-all-button {
	padding: 0rem !important;
	font-size: 0.875rem;
		p {
			@media (max-width: 767px) {
				font-size: 1.1rem;
			}
		}
}
.e-classified-job__filter-opening-no-label {
	font-size: 0.875rem;
	color: #8c8c8c;
}
.e-classified-job__filter-button-cont {
	display: flex;
	justify-content: center;
	width: 100%;
}
.e-classified-job__filter-clear-button {
	margin-left: 1rem;
	margin-right: 1rem;
	margin-top: 0.625rem;
	margin-bottom: 1.5rem;
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
	width: 100%;
	@media screen and (max-width: 767px) {
		p {
			font-size: 1.2rem
		}
	}
}
.e-classified-job__filter-modal-input-container {
	margin-top: 0.313rem;
	margin-bottom: 0.625rem;
}
.e-classified-job__filter-modal-input {
	border-style: solid;
	border-color: #8c8c8c;
	border-radius: 1.25rem;
	&.icon {
		border-right: 0;
		background-color: transparent;
		border-top-right-radius: 0;
    	border-bottom-right-radius: 0;
		// @media screen and (max-width: 767px) {
		// 	height: 1.2em
		// }
	}
	&.input {
		border-left: 0;
		padding-left: 0.375rem;
		font-size: larger;
		@media screen and (max-width: 767px) {
			font-size: 1.2rem
		}
	}
}
.e-classified-job__filter-modal-body-external-container {
	height: 60vh;
	overflow: auto;
}
.e-classified-job__filter-modal-body-container {
	padding-left: 1.563rem;
	padding-right: 1.563rem;
}
.e-classified-job__filter-modal-no-results-container {
	padding-top: 1rem;
}
.e-classified-job__filter-modal-action-button {
	flex: 1;
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
}
.e-classified-job__filter-modal-action-button {
	flex: 1;
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
}
@media screen and (min-width: 768px) and (max-width: 992px) {
	.e-classified-job__filter-container {
		margin-bottom: 3rem;
	}
}

// Job Details

$job-box-shadow: 0px 4px 6px rgba(147, 147, 147, 0.25);
$job-box-radius: 0.5rem;

.e-classified-job-details-cont {
	margin-bottom: 3.75rem;

	.e-classified-job-details-cont__logo-cont {
		display: flex;
		align-items: center;
		// margin-bottom: 2.5rem;
		
		img {
			border-radius: 50%;
			aspect-ratio: 1;
			filter: drop-shadow(3px 3px 4px #836d6d);
			// margin-right: 1.5rem;
			object-fit: cover;
		}

		.e-classified-job-details-cont__logo-title-cont {
			width: calc(100% - 16rem);
			margin-right: 1.1875;
			margin-left: 1.5rem;

			h5 {
				font-size: 1.5rem;
				margin-bottom: 0.5rem;
				font-weight: 700;
			}

			span {
				font-size: 0.8;
				cursor: pointer;
			}
		}

		svg {
			cursor: pointer;
			color: #2254f5;
			width: 2rem;
			height: 2rem;
		}
	}

	.e-classified-job-details-cont__card {
		box-shadow: $job-box-shadow;
		width: 9.375rem;
		padding: 0.75rem;
		display: grid;
		border-radius: $job-box-radius;
		place-items: center;
		margin-right: 2rem;

		p {
			text-align: center;
			
			&:first-child {
				font-size: 0.875rem;
				margin-bottom: 0.5rem !important;
			}

			&:last-child {
				font-size: 1.125rem;
				color: #2254f5;
				font-weight: 600;
			}
		}
	}

	.e-classified-job-details-cont__position-cont,
	.e-classified-job-details-cont__request-cont {
		margin-top: 2.5rem;

		h3 {
			margin-bottom: 1.5rem;
			font-size: 1.125rem;
			font-weight: 600;
		}

		p, li {
			font-size: 1rem;
		}
	}

	.page-action-content {
		@media screen and (max-width: 1199px){
			margin-top: 1rem;
		}
	}

	.e-classified-job-details-cont__summary-cont {
		box-shadow: $job-box-shadow;
		border-radius: $job-box-radius;
		padding: 1.75rem;
		margin-bottom: 2.5rem;

		.e-classified-job-details-cont__summary-cont-salary-title-cont {
			display: flex;
			align-items: center;
			margin-bottom: 1rem;

			svg {
				color: #1e4d5a;
				width: 1.125rem;
				height: 1.125rem;
				margin-right: 0.5rem;
			}

			span {
				font-size: 1rem;
				color: #434343;
			}
		}

		.e-classified-job-details-cont__summary-cont-salary-text {
			font-size: 1.5rem;
			font-weight: 600;
			margin-bottom: 1rem;
		}

		.e-classified-job-details-cont__summary-cont-badge {
			padding: 0.25rem 0.5rem;
			color: white;
			margin-right: 0.5rem;
			border-radius: 0.25rem;

			span {
				font-size: 0.875;
			}
		}

		.e-classified-job-details-cont__summary-cont-action-cont {
			display: flex;
			gap: 1.2rem;
			row-gap: 1.2rem; /* Fallback for older browsers */
			column-gap: 1.2rem; /* Fallback for older browsers */
			flex-wrap: wrap;
			margin-top: 1rem;

			button {
				display: flex;
				align-items: center;
				
				svg {
					width: 1rem;
					margin-left: 0.5rem;
				}
			}

			.save-btn {
				&:hover {
					color: #334e7cd6;
				}
			}
		}
	}

	.e-classified-job-details-cont__company-details-cont {
		padding: 1.5rem 1.875rem;
		box-shadow: $job-box-shadow;
		border-radius: $job-box-radius;
		margin-bottom: 2.5rem;

		.e-classified-job-details-cont__company-details-cont-title-cont {
			display: flex;
			align-items: center;
			cursor: pointer;

			img {
				width: 3.125rem;
				height: 3.125rem;
				object-fit: cover;
				border-radius: 50%;
			}
			.e-classified-job-details-cont__company-details-cont-title-cont-text-cont {
				margin-left: 1rem;
				width: calc(100% - 4.125rem);
				p {
					font-size: 1rem;
				}
			}
		}

		.e-classified-job-details-cont__company-details-cont-data {
			margin-top: 1rem;

			svg {
				width: 1.125rem;
				height: 1.125rem;
				margin-right: 0.5rem;
			}

			span {
				font-size: 1rem;
				margin-right: 0.5rem;
			}

			.clickable {
				&:hover {
					text-decoration: underline;
					cursor: pointer;
				}
			}

			:last-child {
				color: $primary;
			}
		}

		.not-logged-in {
			span:last-child {
				filter: blur(5px);
			}
		}
	}

	.e-classified-job-details-cont__company-benefits {
		box-shadow: $job-box-shadow;
		border-radius: $job-box-radius;
		padding: 1.5rem 1.65rem;
		overflow-y: scroll;
		max-height: 22rem;

		@media screen and (min-width: 787px) and (max-width: 951px) {
			max-height: 32rem;
		}

		@media screen and (min-width: 952px) and (max-width: 1010px) {
			max-height: 31rem;
		}

		@media screen and (min-width: 1011px) and (max-width: 1130px) {
			max-height: 30rem;
		}

		@media screen and (min-width: 1131px) and (max-width: 1199px) {
			max-height: 29rem;
		}

		@media screen and (min-width: 1200px) {
		max-height: 28rem;
		}

		.e-classified-job-details-cont__company-benefits-title {
			font-size: 1.125rem;
			font-weight: 600;
		}

		.e-classified-job-details-cont__company-benefits-card-cont {
			padding: 0 0.25rem;
			
			img	{
				height: 2.25rem;
				
				@media (max-width: 400px) {
					height: 2rem; 
				}
			}
		}

		.e-classified-job-details-cont__company-benefits-card {
			padding: 0.25rem 0.75rem;
			background-color: #f0f4ff;
			display: grid;
			place-items: center;
			border-radius: 0.375rem;
			margin-top: 1rem;
			height: 100%;

			svg {
				color: $primary;
				width: 0.5rem;
				height: 0.5rem;
				margin-bottom: 1rem;
			}

			span {
				font-size: 0.775rem;
				text-align: center;
				
				@media (max-width: 400px) {
					font-size: 0.55rem;
				}
			}
		}
	}

	.e-classified-job-details-cont__company-env-cont {
		margin-top: 3.75rem;

		h5 {
			font-weight: 600;
			font-size: 1.5rem;
			margin-bottom: 1.5rem;
		}

		.bg-secondary {
			margin-bottom: 1.5rem;
			width: 17.5rem;
			height: 12.5rem;
		}
	}
	
	.e-classified-job-details-cont__gallery-img {
		margin-bottom: 3rem;
		width: 100%;
		aspect-ratio: 16/9;
		object-fit: cover;
		
		&:hover {
			cursor: pointer;
			opacity: 0.8;
		}
	}
}

// moved outside hierarchy to share styles with other elements
.e-classified-job-details-cont__company-details-title {
	font-size: 1.125rem;
	font-weight: 600;
	margin-bottom: 1.5rem;
}