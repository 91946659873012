.e-classified-inbox-cont {
	h3 {
		font-weight: 600;
		font-size: 1.5rem;
		margin-bottom: 1.5rem;
	}

	@media screen and (max-width: 768px) {
		margin-top: 2rem;
	}

	.e-classified-inbox-tab-cont {
		display: flex;
		flex-wrap: wrap;

		.e-classified-inbox-tab {
			border-radius: 0px;
			margin-right: 1rem;
			display: flex;
			font-weight: 600;
			font-size: 1rem;
			align-items: center;
			background: transparent;
			border: none;
			padding: 1rem;
			color: #8c8c8c;
		}

		.e-classified-inbox-tab-active {
			border-bottom: 1px solid;
		}
	}

	.e-classified-inbox-item {
		padding: 1.5rem;

		.e-classified-inbox-title {
			font-weight: 600;
			font-size: 1rem;
			color: black;
		}

		.e-classified-inbox-desc {
			font-size: 0.875rem;
			color: #bfbfbf;
		}
	}

	.e-classified-inbox-content-card {
		padding: 1.5rem;
		align-items: center;
		display: flex;
		background: white;
		border-bottom: 0.5px solid #d9d9d9;

		.e-classified-inbox-content-card-title {
			margin-bottom: 0.8rem;
			font-weight: 600;
			display: flex;
			align-items: center;
			cursor: pointer;
			font-size: 1rem;
		}

		.e-classified-inbox-content-card-date {
			color: #bfbfbf;
			font-size: 0.875rem;
		}
	}
}
