.e-classified-dialog-full_screen {
    margin: 15px !important;
    max-width: 100vw !important;
  }
  
  .e-classified-dialog {
    box-shadow: none !important;
  }
  
  .e-classified-dialog-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0.938rem;
    padding-right: 0.938rem;
  }
  
  .e-classified-dialog-header {
    flex: 1;
    font-weight: 600;
    font-size: 1rem;
    padding-top: 0.938rem;
    padding-bottom: 0.938rem;
    padding-left: 0.938rem;
    padding-right: 0.938rem;
  }
  
  .e-classified-dialog-header-icon-container {
    width: 3rem;
  }
  
  .e-classified-dialog-header-icon-button {
    padding: 0;
  }
  
  .e-classified-dialog-content {
    max-height: 82vh;
    overflow: auto;
    box-shadow: 0 0.76875rem 2.4875rem rgba(52, 58, 64, 0.3), 0 1.3375rem 1.70625rem rgba(52, 58, 64, 0.3), 0 0.55rem 0.53125rem rgba(0, 0, 0, 0.05), 0 0.225rem 0.4375rem rgba(52, 58, 64, 0.3) !important;
  }
  
  
  
  
  // Confirmation Modal
  
  .e-classified-advertiser__suspend-modal {
    padding: 1.5rem;
    text-align: center;
  
    h4 {
      margin: 0rem 0.5rem;
      font-size: 1.5rem;
      font-weight: 600;
      overflow-wrap: break-word;
    }
  
    > div {
      display: flex;
      margin-top: 1.5rem;
      justify-content: flex-end;
      gap: 0;
    }
  }