.banner-row {
  row-gap: 30px;

  .each-banner-preview {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .image-container {
      width: 100%;
      height: 200px;
      object-fit: contain;
    }
  }
}

.e-classified-header-cont {
  background: linear-gradient(
    180deg,
    #f0f4ff 37.19%,
    rgba(240, 244, 255, 0) 106.77%
  );
  @media (max-width: 768px) {
    padding: 1rem 2rem !important;

    img {
      margin-bottom: 1rem;
    }
  }
  padding: 2rem 7.5rem;
}

.e-classified-home__header-cont {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 1.125rem;
  max-width: 1400px;
  margin: auto;

  img {
    width: 21.25rem;
  }

  .e-classified-home__header-text-mobile-cont {
    text-align: center;
    margin-bottom: 5rem;
    width: 100%;

    @media screen and (min-width: 768px) {
      display: none;
    }

    h3 {
      color: #262626;
      font-size: 1.2rem;
      font-weight: 700;
    }

    p {
      color: #595959;
    }
  }

  .e-classified-home__header-text-desktop-cont {
    flex: 1;

    @media screen and (max-width: 768px) {
      display: none;
    }

    @media screen and (min-width: 680px) {
      min-width: 200px;
    }

    h3 {
      font-weight: 700;
      font-size: 3.25rem;
    }

    h2 {
      font-weight: 700;
      font-size: 4.375rem;
    }

    p {
      font-size: 1.125rem;
      color: #595959;
    }
  }

  .e-classified-home__header-img-cont {
    text-align: right;
    flex: 1;
    display: none;

    @media screen and (min-width: 768px) {
      display: flex;
    }

    img {
      width: 27.75rem;
    }
  }
}

.e-classified-home__header-nav-cont {
  margin-bottom: 5rem;
  max-width: 1400px;
  margin: auto;
  margin-bottom: 6.25rem;

  .e-classified-home__header-nav {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    row-gap: 5rem;
    column-gap: 1.5rem;

    @media screen and (min-width: 768px) {
      flex-wrap: wrap;
      padding-left: 7.5rem;
      padding-right: 7.5rem;
      margin-top: 7.6875rem;
    }

    @media screen and (max-width: 768px) {
      overflow-x: auto;
      padding-left: 2rem;
      padding-right: 2rem;
      margin-top: 2rem;
      height: calc(100% + 50px);
      justify-content: flex-start;
    }

    @media screen and (min-width: 1400px) {
      justify-content: space-between;
    }

    .e-classified-home__header-nav-card {
      text-align: center;
      padding-left: 6rem;
      padding-right: 6rem;
      padding-bottom: 1.75rem;
      border-radius: 1.5rem;
      box-shadow: 0px 4px 6px 0px #c2c2c240;
      background-color: #e5ecff;
      text-decoration: none;

      @media screen and (max-width: 768px) {
        margin-top: 3rem;
      }

      .e-classified-home__header-nav-card-img {
        width: 7.2rem;
        height: 7.2rem;
        border-radius: 50%;
        object-fit: cover;
        margin-top: -2.6875rem;
        margin-left: auto;
        margin-right: auto;
        display: grid;
        place-content: center;

        background-color: #f0f0f0;

        img {
          width: 9.375rem;
          object-fit: cover;
        }
      }

      @media screen and (max-width: 768px) {
        min-width: 200px;

        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }

      .e-classified-home__header-nav-card-title {
        font-size: 1.5rem;
        font-weight: 600;
        margin-top: 2rem;
        margin-bottom: 1rem;
      }

      .e-classified-home__header-nav-card-desc {
        font-size: 1rem;
      }
    }
  }
}

.e-classified-home__recomended_job-cont {
  position: relative;
  background: linear-gradient(180deg, #e5ecff, transparent);

  padding: 6.25rem 5rem;
  overflow: hidden;

  @media (( min-width: 992px ) and ( max-width: 1400px )) {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  @media screen and (max-width: 768px) {
    padding: 2rem;
  }

  @media screen and (min-width: 1400px) {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .e-classified-home__recomended_job-cont-overlay-img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    left: 0;
  }
}

.e-classified-home__recomended_job_title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto;
}
.e-classified-home__recomended_job_desc {
  margin-bottom: 2.5rem !important;
  color: #595959;
  font-size: 1.125rem;
}

.e-classified-home__blog-title {
  font-size: 2rem;
  margin-top: 5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.e-classified-home__blog-desc {
  margin-bottom: 2.5rem;
  color: #595959;
  font-size: 1.125rem;
}

.e-classified-home__blog-parent-cont {
  margin-bottom: 6.25rem;
  background: linear-gradient(
    0deg,
    #e6ecff 34.83%,
    rgba(240, 244, 255, 0) 100%
  );
}

.e-classified-home__blog-find-more-btn {
  text-align: center;
  color: #1d2c46;
  display: block;
  border-radius: 0.25rem;
  width: max-content;
  padding: 1rem;
  border: 1px solid #1d2c46;
  margin-bottom: 5rem;

  span {
    text-decoration: none;
  }
}

.e-classified-home__best-company-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.e-classified-home__best-company-desc {
  margin-bottom: 2.5rem;
  color: #595959;
  font-size: 1.125rem;
}

.e-classified-home__best-company-cont {
  padding-left: 13.75rem;
  padding-right: 13.75rem;
  padding-bottom: 2.5rem;
  justify-content: center;
  align-items: center;

  display: flex;
  flex-wrap: wrap;
  gap: 5rem;
  row-gap: 5rem; /* Fallback for older browsers */
  column-gap: 5rem; /* Fallback for older browsers */

  @media screen and (max-width: 768px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  img {
    width: 8.5rem;
  }
}

.e-classified-banner-cont {
  text-align: center;
  padding-top: 7.75rem;
  padding-bottom: 7.75rem;

  padding-right: 5.875rem;
  padding-left: 5.875rem;

  @media (min-width: 1400px) {
    padding-right: 6.375rem;
    padding-left: 6.375rem;

    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }

  @media screen and (max-width: 768px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .e-classified-banner-title {
    font-size: 2rem;
    font-weight: 700;
  }

  .e-classified-banner-desktop-img {
    object-fit: cover;
    width: 100%;

    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .e-classified-banner-mobile-img {
    object-fit: cover;
    width: 100%;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}

.e-classified-footer-cont {
  max-width: 1400px;
  margin: auto;
  padding: 2.375rem 7.5rem;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  row-gap: 1rem; /* Fallback for older browsers */
  column-gap: 1rem; /* Fallback for older browsers */
  display: flex;

  .e-classified-footer-cont-menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    a {
      margin-right: 1rem;
      margin-bottom: 0rem;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column !important;
    padding: 1rem 3rem !important;
  }

  @media (min-width: 768px) {
    .e-classified-footer-cont-menu {
      flex-direction: column !important;

      a {
        margin-right: 0rem;
        margin-bottom: 1rem;
      }
    }
  }
}
