// eClassified Card Component
// eClassified Job Card Styles

.e-classified-job__card {
	display: flex;
	flex-direction: column;
	height: 100%;
	border-radius: 0.375rem;
	box-shadow: 0px 4px 6px rgba(175, 175, 175, 0.25);
	padding: 0rem;
	background-color: white;
	&.premium {
		border-width: 1px;
		border-style: solid;
	}
}
.e-classifed-job__label-card {
	width: fit-content;
	border-top-left-radius: 0.375rem;
	border-bottom-right-radius: 0.375rem;
	padding-top: 0.125rem;
	padding-bottom: 0.125rem;
	padding-left: 1.563rem;
	padding-right: 1.563rem;
	background: white;
}
.e-classifed-job__label {
	font-weight: 500;
	font-size: 0.875rem;
	margin-bottom: 0;
}
.e-classified-job__top-container {
	display: flex;
	flex-direction: row;
	padding-top: 1rem;
	padding-bottom: 0.5rem;
	padding-left: 1.563rem;
	padding-right: 1.563rem;
	text-decoration: none;
}
.e-classified-job__image-container {
	width: 3rem;
	height: 3rem;
	border-color: #d9d9d9;
	border-radius: 0.375rem;
	border-style: solid;
	display: flex;
	img {
		width: 100%;
		object-fit: cover;
		align-self: center;
	}
}
.e-classified-job__desc-container {
	display: flex;
	flex-direction: row;
	margin-left: 1.25rem;
	width: calc(100% - 4.25rem);
}
.e-classified-job__title-container {
	display: flex;
	flex-direction: column;
}
.e-classified-job__title {
	font-weight: 600;
	font-size: 1.125rem;
	line-height: 1.25rem;
	margin-bottom: 0rem;
	text-align: left;

	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1; /* number of lines to show */
	line-clamp: 1;
	-webkit-box-orient: vertical;
	white-space: "nowrap";
}
.e-classified-job__company {
	color: #bcbcbc;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.25rem;
	margin-top: 0.313rem;
	margin-bottom: 0rem;
	text-align: left;
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1; /* number of lines to show */
	line-clamp: 1;
	-webkit-box-orient: vertical;
	white-space: "nowrap";
}
.e-classified-job__tag-container {
	display: flex;
	flex-direction: row;
	margin-left: 1.25rem;
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	gap: 0.125rem;
	row-gap: 0.75rem; /* Fallback for older browsers */
	column-gap: 0.75rem; /* Fallback for older browsers */
	overflow: scroll;

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	&:-webkit-scrollbar {
		background-color: transparent;
		width: 0;
	}
}
.e-classified-job__tag {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	border-radius: 0.375rem;
	min-width: max-content;
}
.e-classified-job__tag-text {
	color: #bcbcbc;
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1.25rem;
}
.e-classified-job__bottom-container {
	display: flex;
	align-items: center;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	margin-left: 1.563rem;
	margin-right: 1.563rem;
	border-color: #d9d9d9;
	border-width: 0.5px 0 0 0;
	border-style: solid;
}
.e-classified-job__salary-container {
	display: flex;
	flex-direction: column;
	flex: 1;
}
.e-classified-job__salary {
	font-weight: 600;
	font-size: 1.125rem;
	line-height: 1.5rem;
	margin-bottom: 0rem;
	text-align: left;
}
.e-classified-job__location {
	color: #bcbcbc;
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1.25rem;
	margin-bottom: 0rem;
	text-align: left;
}
.e-classified-job__button-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}
.e-classified-job__share-button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	gap: 0.625rem;

	svg {
		width: 1.25rem;
		height: 1.125rem;
	}
}
.e-classified-job__premium-strip {
	height: 0.375rem;
	border-bottom-left-radius: 0.375rem;
	border-bottom-right-radius: 0.375rem;
}
@media (max-width: 992px) {
	.e-classified-job__card {
		width: 100%;
	}
}
