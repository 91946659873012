.e-classified-advertiser__preview-modal {
    box-shadow: none !important;
    background: none;
    border: none;
    // overflow-y: auto !important;
    align-items: center;
    justify-content: center;
  
    .modal-content {
      width: 80%;
    }
  
    .e-classified-advertiser__preview-modal-body {
      padding: 0;
      position: relative;
      .e-classified-advertiser__close-button {
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-size: large;
        cursor: pointer;
      }
      .e-classified-advertiser__cancel-generate {
        width: 100%;
        margin-top: 0.5rem;
        justify-content: end;
      }
  
      Button {
        margin-top: 1.5rem;
        margin-left: 0.5rem;
      }
      
      span {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 0px;
        margin-top: 0.5rem;
        color: black;
      }
  
      p {
        line-height: normal;
        margin-top: 0.25rem;
        font-size: 0.9rem;
      }
  
      .carousel-indicators {
        bottom: -3rem;
        
        button {
          width: 0.675rem;
          height: 10px;
          border-radius: 10px;
          border: none;
        }
      }
  
      .carousel-indicators .active {
        background-color: var(--primary); 
        height: 10px;
        width: 1rem;
        border-radius: 10px;
        border: none;
      }
  
      .carousel-control-prev {
        width: 3rem;
        left: -3.5rem;
  
        svg {
          font-size: 3rem;
          cursor: pointer;
          color: var(--primary);
        }
      } 
  
      .carousel-control-next {
        width: 3rem;
        right: -3.5rem;
  
        svg {
          font-size: 3rem;
          cursor: pointer;
          color: var(--primary);
        }
      }
      
  
      .e-classified-advertiser__price_detail {
        display: flex;
        align-items: center;
        gap: 0.5rem;
  
        h1 {
          min-width: fit-content;
          font-size: 2.5rem;
          font-weight: 700;
          color: var(--primary);
        }
      }
    }
  }