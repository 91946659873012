.e-classified-profile-view-card {
	padding: 1.5rem;
	color: white;
	box-shadow: 0px 4px 6px 0px #bebebe40;
	border-radius: 0.625rem;

	.e-classified-profile-view-position-badge {
		padding: 0.5rem;
		border: 1px solid;
		border-radius: 0.25rem;
	}

	.e-classified-profile-view-info-subcont {
		display: flex;
		align-items: center;
		margin-bottom: 1.5rem;
		margin-right: 1.5rem;
		color: #262626;

		svg {
			width: 1.25rem;
			height: 1.25rem;
			margin-right: 0.5rem;
		}

		span {
			font-weight: 400;
		}
	}

	.e-classified-profile-tag-cont {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;

		.e-classified-tag-cont-primary {
			background-color: #f0f4ff;
			padding: 0.5rem;
			color: black;
			border-radius: 0.25rem;
		}

		.e-classified-tag-cont-secondary {
			color: #262626;
			background-color: #f0f0f0;
			border-radius: 0.25rem;
			padding: 0.5rem;
		}
	}
}
