.subrow {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .each-item {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .datepicker {
    width: 300px;
  }
}

.upgrade-form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .each-selection {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .selection-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .selection-item {
        display: flex;
        flex-direction: column;
        gap: 2px;
        padding-left: 20px;

        .selection-item-checkbox {
          margin-top: 15px;
        }

        .item-name-group {
          display: flex;
          flex-direction: column;
          gap: 2px;

          .item-name {
            display: flex;
            align-items: center;
            gap: 5px;

            .blue-filter {
              filter: invert(39%) sepia(76%) saturate(1134%) hue-rotate(185deg)
                brightness(102%) contrast(104%);
            }
          }
        }
      }

      .quantity-selector {
        width: 150px;
      }
    }
  }

  .vertical-section {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .each-charge {
      display: flex;
      justify-content: space-between;

      .charge-item {
        display: flex;
        gap: 5px;

        .icon {
          filter: invert(39%) sepia(76%) saturate(1134%) hue-rotate(185deg)
            brightness(102%) contrast(104%);
          margin-top: 3px;
        }

        .charge-item-name-group {
          display: flex;
          flex-direction: column;
          gap: 2px;

          .charge-subtext {
            color: #8c8c8c;
          }
        }
      }
    }

    .voucher-input {
      display: flex;
      gap: 5px;
    }
  }

  .each-price-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .grand-total-font {
      font-size: 24px;
      font-weight: 600;
    }
  }

  .item-price {
    color: #334e7c;
  }
}

.step-progress-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20px;
  margin-bottom: 40px;

  .each-progress {
    width: 18%;
    height: 7px;
    border-radius: 5px;
    background-color: #cccccc;
  }

  .each-progress.fill {
    background-color: #334e7c;
  }
}

.step-graphic {
  height: 29px;
  width: 29px;
  border-radius: 20px;
  background-color: #334e7c;
  color: #ffffff;
}

.single-select-option {
  padding: 8px 12px;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  cursor: pointer;
}

// .single-select-option:not(:last-child) {
//   margin-right: 10px;
// }

.single-select-option.selected {
  border: 1px solid #334e7c;
  background-color: #334e7c;
  color: #ffffff;
}

.multi-select-item {
  padding: 8px 12px;
  border: 1px solid #334e7c;
  background-color: #334e7c;
  color: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.multi-select-item:not(:last-child) {
  margin-right: 10px;
}

.multi-select-option {
  padding: 8px 12px;
  border: 1px dashed #bfbfbf;
  border-radius: 4px;
  cursor: pointer;

  &.selected {
    background-color: #334e7c;
    border: 1px solid #334e7c;
    color: #ffffff;
  }
}

.multi-select-option:not(:last-child) {
  margin-right: 10px;
}

.add-new-container {
  cursor: pointer;
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #bfbfbf;
  border-radius: 4px;
}

.package-option {
  padding: 24px;
  display: flex;
  flex-direction: column;
  border: 1px solid #bfbfbf;
}

.package-option.selected {
  border: 2px solid #334e7c;
}

.package-option:not(:last-child) {
  margin-bottom: 10px;
}

.e-classified-advertiser__add-on {
	padding-left: 0.5rem;
	
	.e-classified-advertiser__add-on-label {
		display: flex;
		align-items: center;
		gap: 0.5rem;
	
		label {
			max-width: 18rem;
			font-size: 1rem;
			font-weight: 600;
			margin-bottom: 0px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	
		span {
			font-weight: 500;
		}
		
		svg {
			width: 1.125rem;
			height: 1.125rem;
			color: var(--primary);
		}
	}

	p {
		margin-bottom: 0px;
		font-weight: 600;
		color: var(--primary);
	}
}

.e-classified-advertiser__charges-item,
.e-classified-advertiser__charges-subitem {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.e-classified-advertiser__charges-item {
	svg {
		width: 20px;
		height: 20px;
		min-width: 20px;
		color: var(--primary);
		cursor: pointer;
		margin-right: 0.5rem;
	}

	h1 {
		font-size: 1.5rem;
		font-weight: 600;
		color: var(--primary);
	}

	span {
		font-size: 1rem;
		font-weight: 500;
		margin-bottom: 0px;
	}
}

.e-classified-advertiser__charges-subitem {
	padding-left: 1rem;
	span {
		font-size: 0.875rem;
		margin-left: 1rem;
		font-weight: 400;
		color: var(--secondary);
	}
}

.-pagination{
  .-next, .-previous{
    display: flex !important;
    .-btn{
      width: 40% !important;
    }

    @media (max-width: 450px) {
      .-btn{
        width: 70% !important;
      }
    }
  }

  .-next{
    margin-left: 8px;
  }

  .-previous{
    justify-content: end;
  }
}