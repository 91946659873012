.e-classfied-me-menu__root-cont {
	p {
		margin-bottom: 0px;
	}

	.text-black {
		color: black;
	}

	.e-classified-cont-edit-label {
		color: #595959;
		font-size: 1rem;
		font-weight: 400;
	}

	.e-classified-cont-header {
		font-size: 1.125rem;
		font-weight: 600;
		color: #434343;
	}
}

.e-classfied-me__cont {
	.e-classfied-want-job__cont {
		margin-bottom: 1.5rem;
		padding: 2rem;
		background-color: white;
		box-shadow: 0px 4px 6px 0px #bebebe40;
		border-radius: 0.625rem;

		.e-classfied-want-job__cont-child-cont {
			display: flex;
			align-items: center;
			margin-bottom: 1.5rem;

			svg {
				width: 1.5rem;
				height: 1.5rem;
				margin-right: 0.5rem;
			}

			strong {
				font-size: 1.125rem;
				font-weight: 600;
			}

			p {
				font-size: 1rem;
				margin-top: 1.5rem;
			}
		}
	}

	.e-classfied-me-menu__cont {
		border-radius: 0.625rem;
		background-color: white;
		box-shadow: 0px 4px 6px 0px #bebebe40;
		padding: 1.5rem;

		.e-classfied-me-menu__cont-item {
			border-radius: 0.25rem;
			padding: 1rem;
			cursor: pointer;
			display: flex;
			align-items: center;
			text-decoration: none !important;
			color: #8c8c8c;

			svg {
				width: 1.25rem;
				height: 1.25rem;
				margin-right: 0.625rem;
			}

			span {
				color: #8c8c8c;
				font-size: 1rem;
				font-weight: 500;
			}
		}
		.e-classfied-me-menu__cont-item-active {
			svg,
			span {
				color: white !important;
			}
		}
	}
}

.e-classfied-me-menu__form-cont {
	label {
		font-size: 1rem;
		font-weight: 500;
		color: #262626;
	}

	input {
		padding: 1rem;
	}
}
