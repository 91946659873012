.e-classified-saved-article-tags-cont {
	display: flex;
	overflow-x: auto;
	white-space: nowrap;
	margin-top: 0.5rem;
	margin-bottom: 1.5rem;
	gap: 1rem;
	row-gap: 1rem; /* Fallback for older browsers */
	column-gap: 1rem; /* Fallback for older browsers */
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	&:-webkit-scrollbar {
		background-color: transparent;
		width: 0;
		background-color: transparent;
	}
}

.e-classified-saved-article-card {
	border-radius: 0.25rem;
	margin-bottom: 1.5rem;

	img {
		aspect-ratio: 3/2;
		width: 100%;
	}
	.e-classified-saved-article-card-action {
		display: flex;
		align-items: center;
		padding: 1.5rem;
		background-color: white;

		span {
			font-weight: 500;
			font-size: 1.125rem;
			color: #262626;
			margin-right: 0.5rem;
		}

		svg {
			width: 1.5rem;
			height: 1.5rem;
			cursor: pointer;
			min-width: 1.5rem;
		}
	}
}
