.preview-modal{
    font-size: 18px !important;
    .top-bg{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 13.75rem;
      background-image: linear-gradient(#F0F4FF, #f0f4ff00);
    }
    .ant-modal-content{
      background-color: #FBFBFB;
      overflow: hidden;
    }
  
    .preview-header{
      .btn-outline-primary{
        border: none;
      }
    }
  
    .preview-content{
      position: relative;
      z-index: 1;
      margin: 0 6.25rem;
    }
  
    .ant-avatar{
      display: flex;
      justify-content: center;
      margin-bottom: 0.5rem;
  
      .anticon{
        font-size: 2rem;
        color: #bbbbbb
      }
    }
  
    .ant-card{
      height: 100%;
    }
  
    h6{
      font-weight: 600;
      font-size: 1rem;
    }
  
    .label-text{
      color: #8C8C8C;
    }
  
    .ant-avatar{
      background-color: #FFFFFF;
      img{
        object-fit: contain;
      }
    }
    
    .fw-bold {
      font-weight: 500;
    }
  }