.pre-create-modal{
    padding: 3rem 4.5rem;
  
    .title, .subtitle {
      text-align: center;
    }
  
    .options-picker{
      text-align: center;
      padding: 4rem 1.5rem;
      width: 100%;
      border-radius: 0.25rem;
      margin-bottom: 2.5rem;
      border: 1px solid #d9d9d9;
      background-color: #FAFAFA;
      text-decoration: none;
      cursor: pointer;
  
          .e-classified-sign-up__identity-card-img {
        width: 100%;
        height: 7.2rem;
        border-radius: 50%;
        position: absolute;
        top: -5rem;
        left: 0px;
    
        img {
          object-fit: cover;
        }
      }
  
      &:hover, &.options-picker-active{
        border-color: #334e7c;
      }
  
      p{
        font-size: 0.875rem;
        text-align: center;
      }
  
      .ant-avatar{
        display: flex;
        justify-content: center;
  
        .anticon{
          font-size: 2rem;
        }
      }
    }
  
    button{
      border-radius: 2px;
    }
  }