.custom-input-group {
  flex-wrap: nowrap;

	.custom-input-prefix {
		font-size: 0.875rem;
		padding: 0.25rem 0.25rem 0.25rem 0.875rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		border: 1px solid #D0D5DD;
		background: #fff !important;
		border-right: 0px !important;
    color: hsl(0, 0%, 20%);
	}

	.custom-input-prefix + .custom-form-input {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0px;
	}
}

.custom-form-select {
  width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	font-size: 12px;
	font-weight: 300;
	background: #fff;
	line-height: 1.25rem;
	position: relative;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  .ant-select-selection-overflow {
    padding: 0rem 0.75rem;
  }

  .ant-select-selector {
    border-radius: 4px;
    &:not(:hover){
      border: 1px solid #d0d5dd !important;
    }
    .ant-select-selection-placeholder {
      font-size: 12px;
      color: #667085;
    }
  }
}

.ant-select-focused:where(.css-dev-only-do-not-override-pr0fja).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: #4096ff !important;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
}

.custom-form-input,
.custom-form-input.form-control {
	display: flex;
	padding: 0.875rem 1rem;
	align-items: center;
	gap: 0.5rem;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	// height: 3.125rem;
	font-size: 0.875rem;
	font-weight: 300;
	align-self: stretch;
	border-radius: 4px;
	border: 1px solid #d0d5dd;
	background: #fff;
	line-height: 1.25rem;
	position: relative;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.custom-form-check-input {
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.5rem;
  
  &:checked {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
  }
}

.custom-form-typeahead {
  .form-control {
    display: flex;
    padding: 0.625rem 0.75rem;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    height: 3.125rem;
    font-size: 0.875rem;
    font-weight: 300;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #d0d5dd;
    background: #fff;
    line-height: 1.25rem;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .custom-form-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eaecf0;
    background-color: #f9fafb;
    margin-left: 0px;
    padding: 0.5rem 1rem;
    color: #667085;
    font-size: 0.75rem;
    font-weight: 600;
    position: sticky;
    top: 0px;
    z-index: 99;
    text-transform: uppercase;
  }

  .custom-form-typeahead-item {
    display: flex;
    align-items: center;
    margin-left: 0px;
    padding: 1rem;
    color: #1c212d;
    font-size: 0.75rem;
    font-weight: 600;

    >div {
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none !important;
      }
    }

    &:hover {
      background-color: #f9fafb;
    }
  }

  .dropdown-menu {
    display: grid !important;
    padding: 0px !important;
    overflow-x: hidden !important;
    max-height: 15rem !important;

    @media (max-width: 991.98px) {
      position: absolute !important;
      top: 45px !important;
      left: 0px !important;
      transform: none !important;
    }
  }

  .dropdown-menu .dropdown-item {
    display: contents;
  }
}

.toggle-button {
  background: transparent;
  position: absolute;
  border: 0;
  bottom: 0;
  right: 0;
  top: 0;
  padding: 0 0.625rem;
}

.custom-form-label {
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  gap: 0.5rem;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #cb171a;
  border-color: #cb171a;

  &:hover:before {
    // compiled to: .form__checkbox-label:hover:before
    background-color: #cb171a;
    height: 2.5rem;
  }
}

.custom-radio-button {
  padding: 0.875rem 1rem;
  border-radius: 4px;
}

.custom-toggle-button {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  
  .form-switch {
    padding-left: 0rem;
  }

  .form-check-input {
    width: 2.75rem;
    height: 1.625rem;
    &:checked {
      background-color: var(--primary) !important;
      border-color: var(--primary) !important;
    }
  }

  .form-check-label {
    font-size: 1.125rem;
    font-weight: 500;
    margin-right: 3rem;
    margin-top: 0.2rem;
  }
}

.custom-cart-button {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid var(--light);

  button:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  button:last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  span { 
    text-align: center;
    min-width: 3rem;
    padding: 0.25rem; 
  }
}

.custom-helptext {
  color: var(--light);
  font-size: 0.75rem;
}