.selectSpecificUser{
    display: flex;
    width: 100%;

    .css-b62m3t-container{
        width: 90%;
    }

    .add-btn{
        width: 10%;
        background-color: #334e7d;
        color: white;
        border: none;
        border-radius: 0.5rem;
        padding: 0.5rem;
        margin-left: 0.5rem;
    }
}