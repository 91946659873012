.blue-filter {
  filter: invert(39%) sepia(76%) saturate(1134%) hue-rotate(185deg)
    brightness(102%) contrast(104%);
}

.subrow {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .each-row {
    display: flex;
    gap: 25px;

    .each-item {
      display: flex;
      gap: 5px;

      .blue-text {
        color: #1890ff;
      }
    }
  }
}
